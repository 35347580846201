<template>
	<div class="ed">
		<div class="ed-title">{{ info.inquiryRequestVO.documentText }}</div>
		<div class="ed-info">
			<div class="ed-info-i">
				<span>询价人:</span>
				<p>{{ info.inquiryRequestVO.buyerEnterpriseName }}/{{ info.inquiryRequestVO.buyerName }}/{{
					info.inquiryRequestVO.buyerPhone }}
				</p>
			</div>
			<div class="ed-info-i">
				<span>询价单号:</span>
				<p>{{ info.inquiryRequestVO.inquiryNo }}</p>
			</div>
			<div class="ed-info-i">
				<span>制单时间:</span>
				<p>{{ info.inquiryRequestVO.documentTime }}</p>
			</div>
		</div>
		<div class="line"></div>
		<el-tabs v-model="activeNav">
			<el-tab-pane :label="item.title" :name="item.type" :key="item.type" :disabled="item.disabled"
				v-for="item in navs">
			</el-tab-pane>
		</el-tabs>
		<div class="ed-content">
			<component @bjSuccess="bjSuccess" :info="info" :quoteInfos="quoteInfos" :is="comName"></component>
		</div>
	</div>
</template>

<script>
import productInfo from "../components/enquiryDetails/productInfo.vue" //产品信息
import inquiryInfo from "../components/enquiryDetails/inquiryInfo.vue" //询价信息
import offerInfo1 from "../components/orderDetails/offerInfo1.vue" //报价信息1
import offerInfo2 from "../components/orderDetails/offerInfo2.vue" //报价信息2
import editOfferInfo from "../components/enquiryDetails/editOfferInfo.vue" //编辑报价信息
import {
	mapActions,
	mapState
} from "vuex"
import moment from 'moment';
export default {
	data() {
		return {
			navs: [{
				title: '询价信息',
				type: 3,
				name: 'inquiryInfo'
			},
			{
				title: '产品信息',
				type: 2,
				name: 'productInfo'
			},
			],
			activeNav: 3,
			info: {
				inquiryRequestVO: {
					address: {},
				}
			},
			type: 'customer', //my我的询价详情 customer 客户询价详情(默认我的询价详情)
			offerInfo: {}, //报价信息
			quoteInfos: {}, // 报价单要用到的公司等信息
		}
	},
	components: {
		productInfo,
		inquiryInfo,
		offerInfo1,
		offerInfo2,
		editOfferInfo,
	},
	methods: {
		...mapActions('mysaleMudule', [
			'inquirySellerInfo', //获取询价详情
			'quoteInfo', //获取报价信息
		]),
		getDetails() { //获取询价信息
			this.inquirySellerInfo({
				inquiryId: this.$route.query.inquiryId,
				type: this.$route.query.type

			}).then(res => {
				let {
					code,
					data
				} = res.data;
				if (code === "0") {
					//data.inquiryRequestVO.documentTime = moment(data.inquiryRequestVO.documentTime).format('YYYY.MM.DD hh:mm:ss');
					data.inquiryRequestVO.priceUnit = this.getType('moneys', data.inquiryRequestVO.priceUnit);
					data.inquiryRequestVO.projectFile = JSON.parse(data.inquiryRequestVO.projectFile || '[]');
					data.inquiryRequestVO.file = JSON.parse(data.inquiryRequestVO.file || '[]');
					data.inquiryRequestVO.documentText = this.documentState[data.inquiryRequestVO
						.documentState];
					data.inquiryRequestVO.paymentType = this.getType('paymentTypes', data.inquiryRequestVO
						.paymentType);
					data.inquiryRequestVO.projectState = data.inquiryRequestVO.projectState ?
						this.getType('projectState', data.inquiryRequestVO.projectState) : '';
					if ((data.forEnterprise || data.forSku) || this.type === 'customer') {
						this.activeNav = 1;
						this.navs.unshift({
							title: '报价信息',
							type: 1,
							name: 'offerInfo1'
						})
						if (this.type === 'customer') {
							this.navs[this.matchingIndex(1)].name = 'editOfferInfo'; //编辑报价信息
						} else if (data.inquiryRequestVO.isPlatform === '1') { //全平台报价
							this.navs[this.matchingIndex(1)].name = 'offerInfo2'
						} else { //指定企业报价
							this.navs[this.matchingIndex(1)].name = 'offerInfo1'
						}
					}
					data.itemRequestVOList.reverse()
					this.info = data; //商品列表
				}
			})
		},
		getType(attribute, type) {
			return type ? attribute === 'paymentTypes' && !this[attribute].filter(v => v.type === type).length ?
				this[attribute][0].title : this[attribute].filter(v => v.type === type).length ?
					this[attribute].filter(v => v.type === type)[0].title : '' : '';
		},
		bjSuccess() { //报价成功刷新页面信息
			this.getDetails();
		},
		getOfferInfo() { //获取报价信息
			this.quoteInfo({
				quoteId: this.$route.query.id
			}).then(res => {
				let {
					code,
					data
				} = res.data;
				if (code === '0') {
					this.quoteInfos = data.quoteRtnVO;
					// console.log(data.quoteRtnVO, '-----')
				}
			})
		},
		matchingIndex(type) {
			return this.navs.findIndex(v => v.type === type);
		},
	},
	computed: {
		...mapState('purchaseMudule', [
			'paymentTypes',
			'protects',
			'moneys',
			'documentState',
			'projectState',
		]),
		comName() { //组件名字
			return this.navs.filter(v => this.activeNav === v.type)[0].name;
		},
	},
	created() {
		this.getDetails();
		this.getOfferInfo(); //获取报价信息
	}
}
</script>

<style scoped lang="less">
.ed {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;

	.ed-info {
		display: flex;
		padding-bottom: 15px;

		.ed-info-i {
			font-size: 14px;
			display: flex;
			flex: 1;

			span {
				color: #999999;
				width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			p {
				flex: 1;
				padding-right: 40px;
			}
		}
	}

	.ed-title {
		padding-bottom: 5px;
		color: @dh-color;
		font-weight: bold;
		line-height: 30px;
	}

	.line {
		height: 15px;
		background: #F2F5FA;
		width: calc(100% + 30px);
		position: relative;
		left: -15px;
	}

	.ed-content {
		flex: 1;
		overflow-y: auto;
	}
}
</style>
