<template>
  <div class="p-top" v-if="info.inquiryRequestVO.file && info.inquiryRequestVO.file.length">
    <el-image
        :src="info.inquiryRequestVO.file && info.inquiryRequestVO.file.length ? info.inquiryRequestVO.file[0].url : ''"
        style="width: 300px;height: 180px;object-fit: cover;"
    />
    <div class="p-remarks">
      <span>备注:</span>
      <p>{{ info.inquiryRequestVO.remarks }}</p>
    </div>
  </div>
  <h1 style="margin-bottom:10px">总共{{ info.itemRequestVOList.length }}种商品，共{{dataTotal}}件</h1>
  <lida-table
      :pagination="false"
      :cutHeight="30"
      :data="info.itemRequestVOList || []"
      border
      ref="table">
    <lida-table-column label="产品信息" fixed>
      <template #default="scope">
        <div class="g-info">
          <div class="g-icon">
            <img :src="scope.row.src"/>
          </div>
          <div class="g-right">
            <div class="g-r-title">
              {{ scope.row.productName }}
              <div class="label" :title="scope.row.brandName">{{ scope.row.brandName }}</div>
            </div>
            <div class="g-r-data">
              {{ scope.row.barCode || '无' }}
              <span>|</span>
              {{ scope.row.seriesName || '无' }}
              <span>|</span>
              {{ scope.row.skuNumber || '无' }}
            </div>
          </div>
        </div>
      </template>
    </lida-table-column>
    <lida-table-column label="订货号" prop="articleNo"/>
    <lida-table-column label="面价" prop="guidePrice"/>
    <lida-table-column label="数量" prop="skuQty" fixed="right"/>
  </lida-table>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: ['info'],
  computed: {
    dataTotal() {
      return this.info.itemRequestVOList.reduce((pre, cur) => {
        return pre + cur.skuQty * 1
      }, 0)
    }
  },
  methods: {},
}
</script>

<style scoped lang="less">
.p-top {
  display: flex;
  align-items: stretch;
  font-size: 14px;
  padding-bottom: 15px;

  .p-remarks {
    display: flex;
    padding-left: 20px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999999;
      padding-right: 10px;
    }
  }
}
</style>
